define('saveBuildTemplate', ['jquery', 'review', 'reviewStateProcessor', 'viewData', 'floatingAlert', 'validationAlert', 'overlay'], function ($, review, reviewStateProcessor, viewData, floatingAlert, validationAlert, overlay) {
	'use strict';

	var init = function () {
        $(document).on('click', '.save-build-template', function (e) {
            saveBuildTemplateButtonClicked();
        });
		$('.bottom-bar-button.cancel').click(cancelButtonClicked);
	};

	var cancelButtonClicked = function () {
		window.location = '/Build/BuildTemplateManagement/';
	};

    var saveBuildTemplateButtonClicked = function () {

        // add overlay to prevent unwanted clicks
        overlay.addOverlay();



		var reviewState = review.getReviewState();
		var processedState = reviewStateProcessor.processReviewStateData(reviewState);
		var stateJSON = JSON.stringify(processedState);
		var label = $('.template-management-form-content input').val();

		// do validation
		if (label.trim() == '') {
            var messages = [];
            messages.push('You must enter a Name for the Product Customization');
            validationAlert.showAlert(messages);
            $('.template-management-form-content input').addClass('missing');
            overlay.removeOverlay();
            return;
        }
                        
		var hasCustomizations = false;
		if (reviewState.hotspots && (reviewState.hotspots.length > 0)) {
			for (var i = 0; i < reviewState.hotspots.length; i++) {
				var hotspot = reviewState.hotspots[i];
				if (!isNaN(hotspot.TemplateID)) {
					hasCustomizations = true;
					break;
				}
			}
		}
        if (!hasCustomizations) {
            // allow empty customizations to occur.  TF - 2/2/18
			//floatingAlert.showAlert('You must customize the Product in some way before you can save the Customization Template.');
			//return;
		}

		var $form = $('<form>').addClass('hidden').attr('action', '/Build/BuildTemplateManagement/SaveBuildTemplate').attr('method', 'post');
		$form.append($('<textarea>').addClass('hidden').attr('name', 'BuildID').html(viewData.BuildID));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'TemplateID').html(viewData.BuildTemplateID));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'ProductCode').html(viewData.ProductCode));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'ProjectID').html(viewData.ProjectID));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'ProjectColorID').html(viewData.ProductColorID));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'VariationID').html(viewData.VariationID));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'ProductName').html(viewData.ProductName));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'BuildJSON').html(stateJSON));
		$form.append($('<textarea>').addClass('hidden').attr('name', 'Label').html(label));
		$('body').append($form);
		$form.submit();
	};

	return {
		init: init
	};
});
require.undef('templateData');
define('templateData', ['http'], function (http) {
	'use strict';

	var getTemplatesForHotspotTemplateGroup = function (id) {
		var params = {
            templateGroupID: id,
		};
		return http.get('/Build/BuildTemplateManagement/TemplateList', params).then(processResponse);
	};	

	var getTemplate = function (id) {
		var params = {
			id: id
		};
		return http.get('/Build/BuildTemplateManagement/TemplateDetails', params).then(processResponse);
	};

	var processResponse = function (response) {		
		return response.Data;
	};	

	return {
		getTemplatesForHotspotTemplateGroup: getTemplatesForHotspotTemplateGroup,
		getTemplate: getTemplate
	};
});
require(['perspectiveListing', 'perspectivePreview', 'hotspotPreview', 'menu', 'templateForm', 'review', 'templateGroups', 'loadedTemplates', 'perspectiveHotspotPreview', 'imageSelectorFieldControl', 'applyHotspot', 'saveBuildTemplate', 'savedBuildInitializer', 'buildTemplateForm', 'updateUI', 'overlay'], function
    (perspectiveListing, perspectivePreview, hotspotPreview, menu, templateForm, review, templateGroups, loadedTemplates, perspectiveHotspotPreview, imageSelectorFieldControl, applyHotspot, saveBuildTemplate, savedBuildInitializer, buildTemplateForm, updateUI, overlay) {
	'use strict';

    var init = function () {
        // add overlay to prevent unwanted clicks
        overlay.addOverlay();
        setTimeout(function () {
            overlay.removeOverlay();
        }, 2000);


		// run init functions for relevant modules
		review.init();
		perspectivePreview.init();
		menu.init();
		hotspotPreview.init();
		templateGroups.init();
		templateForm.init();
		loadedTemplates.init();
		perspectiveHotspotPreview.init();
		imageSelectorFieldControl.init();
		applyHotspot.init();
		saveBuildTemplate.init();

		// run initializeUI functions for relevant modules
		perspectiveListing.initializeUI();
		perspectivePreview.initializeUI();

		// initialize the saved build
		savedBuildInitializer.initializeBuild();

		review.refreshReviewUI();

        buildTemplateForm.init();
        updateUI.init();
	};

	init();
});